import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/packages/gatsby-theme-fuegodocs/src/components/Layout/Layout.js";
import { Code } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
import { Component } from "react";
import { InlineCode } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ArticlePageWrapper = makeShortcode("ArticlePageWrapper");
const BackButton = makeShortcode("BackButton");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const ArticlePageDivider = makeShortcode("ArticlePageDivider");
const DoDontChecklist = makeShortcode("DoDontChecklist");
const DoDontChecklistHeading = makeShortcode("DoDontChecklistHeading");
const DoDontChecklistItem = makeShortcode("DoDontChecklistItem");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ArticlePageWrapper mdxType="ArticlePageWrapper">
      <BackButton mdxType="BackButton" />
      <Heading as="h1" mdxType="Heading">Accessibility</Heading>
      <Text mdxType="Text">
  When you're designing or implementing a page,{" "}
  <b>make sure to address the questions below</b>. This ensures that every page
  is navigable by users of assistive technology.
      </Text>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">
    Does the page have a clear structure?
  </DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use heading tags <InlineCode mdxType="InlineCode">h1 - h6</InlineCode> to create page structure.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Clear structure helps to navigate page faster and understand its contents.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Each page should have only one <InlineCode mdxType="InlineCode">h1</InlineCode> tag.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't skip heading levels.
  </DoDontChecklistItem>
  <Link href="https://www.w3.org/WAI/tutorials/page-structure/headings/" target="_blank" mdxType="Link">
    Learn more about heading structure.
  </Link>
      </DoDontChecklist>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">
    Do all lists use appropriate component?
  </DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    This is necessary for screen readers to recognize it as a list and
    communicate it appropriately.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    This allows somebody using a screen reader to easily skip long lists.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use <InlineCode mdxType="InlineCode">ol</InlineCode> for lists where order of item matters and{" "}
    <InlineCode mdxType="InlineCode">ul</InlineCode> where it doesn't.
  </DoDontChecklistItem>
  <Link href="https://www.w3.org/WAI/tutorials/page-structure/content/#lists" target="_blank" mdxType="Link">
    Learn more about lists.
  </Link>
      </DoDontChecklist>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">
    Do all images have alt tags?
  </DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
   Alt tag should contain a short description of an image.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Alt tag for decorative images can be left blank (you still need to
            include an alt tag, like this: <InlineCode mdxType="InlineCode">alt=""</InlineCode>).
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't include "image of" or a similar prefix inside the alt text.
            For example, write <InlineCode mdxType="InlineCode">alt="white cat"</InlineCode> instead
            of <InlineCode mdxType="InlineCode">alt="image of a white cat"</InlineCode>.
  </DoDontChecklistItem>
   <Link href="https://abilitynet.org.uk/news-blogs/five-golden-rules-compliant-alt-text" target="_blank" mdxType="Link">
          Learn more about writing alt text.
    </Link>
      </DoDontChecklist>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Are links descriptive?</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Links should describe their exact action (for example, "Learn more about
    pricing" instead of "Learn more").
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    For icon links (or if there's not enough space to write a descriptive
    label), include an <InlineCode mdxType="InlineCode">aria-label</InlineCode>.
  </DoDontChecklistItem>
  <Link href="https://www.a11y-collective.com/the-perfect-link/" target="_blank" mdxType="Link">
    Learn more about writing links.
  </Link>
      </DoDontChecklist>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">
   Can you navigate the page using a keyboard?
  </DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    All clickable elements (link, button, form element, navigation)
            should have a visible and consistent focus state.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
     If there's a modal, the focus should shift to the modal when it's
            opened.
  </DoDontChecklistItem>
        <Link href="https://www.smashingmagazine.com/2022/11/guide-keyboard-accessibility-html-css-part1/" target="_blank" mdxType="Link">
  Learn more about keyboard accessibility.
        </Link>
      </DoDontChecklist>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Tools</DoDontChecklistHeading>
  <DoDontChecklistItem mdxType="DoDontChecklistItem">
    <Link href="https://wave.webaim.org/extension/" target="_blank" mdxType="Link">
      WAVE
    </Link>{" "}
    — Extension for quick page scans.
  </DoDontChecklistItem>
  <DoDontChecklistItem mdxType="DoDontChecklistItem">
    <Link href="https://www.w3.org/WAI/" target="_blank" mdxType="Link">
      Web Accessibility Initiative
    </Link>{" "}
    — strategies, standards, and resources to make the Web accessible to people
    with disabilities.
  </DoDontChecklistItem>
  <DoDontChecklistItem mdxType="DoDontChecklistItem">
    <Link href="https://www.figma.com/community/plugin/748533339900865323/Contrast" target="_blank" mdxType="Link">
      Contrast
    </Link>{" "}
    — Figma plugin for checking color contrast.
  </DoDontChecklistItem>
  <DoDontChecklistItem mdxType="DoDontChecklistItem">
    <Link href="https://www.smashingmagazine.com/2021/03/complete-guide-accessible-front-end-components/" target="_blank" mdxType="Link">
      A Complete Guide to Accessible Front-End Components
    </Link>{" "}
    — article by Smashing Magazine.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <ArticlePageDivider mdxType="ArticlePageDivider" />
      <Text mdxType="Text">
  For a deeper dive, visit{" "}
  <Link href="https://appfireteam.atlassian.net/wiki/spaces/experience/pages/95735251982/Accessible+design+at+Appfire" target="_blank" mdxType="Link">
    Accessible Design at Appfire in Confluence
  </Link>
  .
      </Text>
    </ArticlePageWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      